import React from 'react'
import Layout from "../components/layout";
import '../styles/contact.scss'
import '../styles/cookies.scss'
import {Link} from "gatsby";

const Support = () => (
    <Layout>
        <section className='section-cookies'>
            <div>
                <div className='heading'>Frequently Asked Questions</div>

            {/*    FAQs start   */}
                <div className='content'>
                    <h3>1. How fast can I get paid?</h3>
                    <p>The speed of deposits varies based on the bank where your business checking account is held. Deposits typically take 1-3 days, but many businesses will see their funds deposited the next business day.</p>
                </div>

                <div className='content'>
                    <h3>2. What is the minimum contract period?</h3>
                    <p>There is no contract period, no termination fees, you can step out whenever you want.
                        But if the billing for current month is already started then you won't get any refund.
                    </p>
                </div>

                <div className='content'>
                    <h3>3. How to disable the Auto-Lock on iPad/iPhone?</h3>
                    <p>
                        <p>To avoid interruptions to your cashiers' workflow, we recommend that you disable Auto-Lock on your iPad/iPhone device. Here's how:</p>
                        <ol>
                            <li>On the iPad/iPhone, open the Settings app, switch to Display and Brightness, then tap Auto-Lock</li>
                            <li>Set Auto-Lock to Never</li>
                        </ol>
                    </p>
                </div>

                <div className='content'>
                    <h3>4. How to connect Terminal?</h3>
                    <p>
                        <ol>
                            <li>Turn ON your BB POS Terminal</li>
                            <li>On the DD Digital app, goto Dashboard and open Setting</li>
                            <li>Select Terminal</li>
                            <li>Select the BB POS terminal under DISCOVERED READER</li>
                            <li>Once the terminal is connected, it will show on the top</li>
                        </ol>
                    </p>
                </div>

                <div className='content'>
                    <h3>5. How to connect Printer?</h3>
                    <p>
                        <ol>
                            <li>Turn ON your Bluetooth Printer</li>
                            <li>Turn ON your device Bluetooth (goto Settings and turn ON Bluetooth)</li>
                            <li>Select your bluetooth Printer under MY DEVICES</li>
                            <li>Once the Printer is Paired, open the DD Digital app, goto Dashboard and open Setting</li>
                            <li>Select Receipt Printer</li>
                            <li>Select your Printer under DISCOVERED PRINTER</li>
                            <li>Once the Printer is connected, tap on Test Print to check the connection</li>
                        </ol>
                    </p>
                </div>

                <div className='content'>
                    <h3>6. Which printers are supported?</h3>
                    <p>Currently we support the Epson Bluetooth Thermal Printers (TM-m30), uses paper roll of Width: 79.5 ± 0.5mm and Paper Roll Diameter: 80mm.</p>
                </div>

                <div className='content'>
                    <h3>7. What are the ideal sizes for product images?</h3>
                    <p>
                        <p>If you want to upload product images to your Management Console so they display on the Point of Sale, here are our size recommendations: </p>
                        <ul>
                            <li>100x100 minimum, 500x500 maximum</li>
                            <li>Square images are best, with rectangles being second best</li>
                            <li>The ideal resolution is 72 pixels per inch</li>
                            <li>The supported file type is .jpg and .png.</li>
                        </ul>
                    </p>
                </div>
            {/*    FAQs end     */}

            </div>
        </section>
    </Layout>
);

export default Support
